import {
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import _ from 'lodash';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loader.service';
import { CFAPIService } from 'src/app/services/api.service';
import { IUser } from 'src/app/models/users.model';
import { FormGroupComponent } from 'src/app/shared/pages/form-group/form-group.component';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

// @ts-ignore
// const api = require('@modules/cf-api.js').api;;
// const api_async = require('@modules/cf-api.js').api_async;

@Component({
    selector: 'app-password-form',
    templateUrl: './password-form.component.html',
    styleUrls: ['./password-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class PasswordFormComponent extends FormGroupComponent<any> implements OnInit {

    @Input() user: IUser;
    showPassword: boolean = false;


    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected s_alert: AlertService,
        protected cd: ChangeDetectorRef,
        protected s_loader: LoaderService,
        protected dialog: MatDialog,
        private fb: FormBuilder,
        protected s_auth: AuthService,
        protected translate: TranslateService
    ) {
        super(route, router, s_alert, s_loader, s_auth);
        this.s_loader.loading = false;
    }

    override initForm() {
        this.form = this.fb.group({
            currentPassword: new FormControl('', [Validators.required]),
            newPassword: new FormControl('', [Validators.required]),
            newPasswordConfirm: new FormControl('', [Validators.required])
        }, { validators: this.checkPasswords });
    }

    checkPasswords: ValidatorFn = (controls: AbstractControl): ValidationErrors | null => {
        let newPassword = controls.get('newPassword')?.value;
        let newPasswordConfirm = controls.get('newPasswordConfirm')?.value;
        return newPassword === newPasswordConfirm ? null : { notSame: true };
    };

    toggleVisibility(): void {
        this.showPassword = !this.showPassword;
    }
}