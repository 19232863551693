<div class="container-password">
    <form [formGroup]="form" #formDirective="ngForm">
        <div>
            <mat-form-field  class="w-full">
                <mat-label>{{'USER.PASSWORD.CURRENT'| translate}}</mat-label>
                <input matInput formControlName="currentPassword" class="w-full" placeholder="" [type]="showPassword ? 'text' : 'password'">
                <mat-icon matSuffix (click)="toggleVisibility()">{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                <span class="text input-error w-full" *ngIf="controls.currentPassword.hasError('required') && controls.currentPassword.pristine === false">
                    {{'REQUIRED'| translate}}
                </span>
            </mat-form-field>
        </div>
        <div class="mt-4 w-full">
            <div>
                <mat-form-field  class="w-full">
                    <mat-label>{{'USER.PASSWORD.NEW'| translate}}</mat-label>
                    <input matInput formControlName="newPassword" class="w-full" placeholder="" [type]="showPassword ? 'text' : 'password'">
                    <span class="text input-error w-full" *ngIf="controls.newPassword.hasError('required') && controls.newPassword.pristine === false">
                        {{'REQUIRED'| translate}}
                    </span>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field  class="w-full">
                    <mat-label>{{'USER.PASSWORD.NEW_CONFIRM'| translate}}</mat-label>
                    <input matInput formControlName="newPasswordConfirm" class="w-full" placeholder="" [type]="showPassword ? 'text' : 'password'">
                    <span class="text input-error w-full" *ngIf="controls.newPasswordConfirm.hasError('required') && controls.newPasswordConfirm.pristine === false">
                        {{'REQUIRED'| translate}}
                    </span>
                    <span class="text input-error w-full" *ngIf="form.hasError('notSame') && controls.newPasswordConfirm.pristine === false">
                        {{'USER.PASSWORD.NOT_SAME' | translate}}
                    </span>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>