import { Injectable } from "@angular/core";
import { ResolverService } from "./api/resolver.service";
import { ExternStateName, InternStateName, State } from "../models/states.model";
import { LivecoAPIService } from "./liveco-api.service";
import { KFeathers } from "./api/feathers.service";
import { FlowType } from "../models/proofreading.model";
import _ from "lodash";
import { MatDialog as MatDialog } from "@angular/material/dialog";
import { AlertService } from "./alert.service";
import { LoaderService } from "./loader.service";


@Injectable({ providedIn: 'root' })
export class StateMachineService extends ResolverService<State> {
    path: string = 'state-machine';
    model: new (any: any) => State = State;

    constructor(
        protected feather: KFeathers,
        protected liveco: LivecoAPIService,
        protected alert: AlertService,
        protected dialog: MatDialog,
        protected loader: LoaderService,
    ) {
        super(feather);
        this.find().then(() => console.log('[STATES]', this.entities));
    }


    hasOptions(opts: string, slug: InternStateName | ExternStateName, flow: FlowType, type: 'intern' | 'extern') {
        let state = this.entities.find(e => e.slug === slug && e.flow.includes(flow) && e.type === type);
        return state?.opts.includes(opts);
    }

    hasPayloadFlag(payload_opts: string, slug: InternStateName | ExternStateName, flow: FlowType, type: 'intern' | 'extern') {
        let state = this.entities.find(e => e.slug === slug && e.flow.includes(flow) && e.type === type);
        return state?.payload?.flags && state.payload?.flags[payload_opts];
    }

    async getState(slug: InternStateName | ExternStateName, flow: FlowType, type: 'intern' | 'extern') {
        let state = this.entities.find(e => e.slug === slug && e.flow.includes(flow) && e.type === type);
        if (state)
            return state;
        state = (await this.find({ slug, flow, type }, { $needReloadList: false }))[0];
        if (state)
            return state;
        throw new Error(`[STATE-MACHINE] Not found state : ${slug} ${flow} ${type}`);
    }
}