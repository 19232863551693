import {
    ChangeDetectorRef,
    Component,
    ViewEncapsulation
} from "@angular/core";
import { Router } from "@angular/router";
import _ from "lodash";
import { INotification } from "src/app/models/notification.model";
import { LivecoAPIService } from "src/app/services/liveco-api.service";
import { NotificationsService } from "src/app/services/notifications.service";
//@ts-ignore
import Mustache from 'mustache';
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";

// // @ts-ignore
// const api = require("@modules/cf-api.js").api;
// const api_async = require("@modules/cf-api.js").api_async;

@Component({
    selector: "app-notif-list",
    templateUrl: "./notif-list.component.html",
    styleUrls: ["./notif-list.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class NotifListComponent {

    get notifs() {
        return this.notifications.entities;
    }

    get unseen() {
        return this.notifs.filter(n => !n.seen);
    }

    constructor(
        public router: Router,
        protected notifications: NotificationsService,
        protected liveco: LivecoAPIService,
        protected translate: TranslateService,
        protected cd: ChangeDetectorRef,
    ) { }

    async goTo(notif: INotification) {
        // this.router.navigateByUrl('/', { skipLocationChange: true,  }).then(() => {
        //     this.router.navigate([notif.url], { queryParams: notif.data.queryParams ? notif.data.queryParams : {}, });
        // });
        if (!notif.opts?.external) {
            const url = this.router.serializeUrl(this.router.createUrlTree([notif.url], { queryParams: notif.data.queryParams ? notif.data.queryParams : {} }));
            window.open('#/' + url, '_blank');
        } else {
            window.open(notif.url, '_blank');
        }
    }

    getIcon(notif: INotification) {
        switch (notif.type) {
            case 'proofscope':
                return 'find_in_page';
            case 'error':
                return 'error';
            case 'briefing':
                return 'hourglass_bottom';
            case 'brief-files':
                return 'folder';
            case 'download':
                return 'download';
            case 'package':
                return 'local_shipping';
            default:
                return notif.type;
        }
    }

    getMessage(notif: INotification) {
        const message = this.translate.instant(notif.message);
        return Mustache.render(message, {
            ...notif.data
        });
    }

    async deleteNotif() {
        const promises = [];
        for (let notif of this.notifs) {
            if (notif._id)
                promises.push(this.liveco.delete(notif._id, 'notifications'));
        }
        await Promise.all(promises);
        await this.notifications.init();
        this.cd.markForCheck();
    }

    formatDate(date: Date | string) {
        return moment(date).isBefore(moment().subtract(1, 'day')) ? moment(date).format('DD/MM/YYYY') : moment(date).format('HH:mm');
    }

    async onOpen() {
        for (let notif of this.notifs) {
            if (notif._id && !notif.seen)
                await this.liveco.patch(notif._id, 'notifications', { seen: true });
        }
        await this.notifications.init();
    }
}
