import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { IUploadingStatus } from 'src/app/services/file.service';

export interface UploadDialogOptions {
    mode: 'uploading' | 'downloading' | 'archiving';
    status: IUploadingStatus | null;
    title: string,
    text: string;
}


@Component({
    selector: 'app-uploading-dialog',
    templateUrl: './uploading-dialog.component.html',
    styleUrls: ['./uploading-dialog.component.scss']
})
export class UploadingDialogComponent implements OnDestroy {
    protected _defaultCtx: UploadDialogOptions = {
        mode: 'uploading',
        status: null,
        title: 'UPLOADING',
        text: 'UPLOADING_IN_PROGRESS'
    };

    public ctx: UploadDialogOptions;

    protected sub: Subscription = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<UploadingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: UploadDialogOptions
    ) {
        this.ctx = this.data;
        this.dialogRef.disableClose = true;
        if (!this.ctx.status) {
            this.dialogRef.close();
        }
        this.sub.add(this.ctx.status?.uploading.subscribe((value: boolean) => {
            if (!value)
                this.dialogRef.close();
        }));
        this.ctx = {
            ...this.ctx,
            title: this.ctx.mode === 'downloading' ? 'DOWNLOADING' : ( this.ctx.mode === 'archiving' ? 'ARCHIVING' : 'UPLOADING'),
            text: this.ctx.mode === 'downloading' ? 'DOWNLOADING_IN_PROGRESS' : ( this.ctx.mode === 'archiving' ? 'ARCHIVING_IN_PROGRESS' : 'UPLOADING_IN_PROGRESS'),
        };
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
