import { BaseModel } from "./base.model";

export interface ISftp {
    file_path: string;
}

export interface ISftpConfig {
        host: string;
        port: number;
        username: string;
        password: string;
        remotePath: string;
    }

export interface ISftpConfigHandler  {
    active: boolean;
    config: ISftpConfig
  }

export class Sftp extends BaseModel<ISftp> implements ISftp {
    file_path: string;

    constructor(data: Partial<ISftp>) {
        super(data);
        this.init(data);
    }

}

export class SftpConfigHandler extends BaseModel<ISftpConfigHandler> implements ISftpConfigHandler {
    active: boolean;
    config: ISftpConfig;

    constructor(data: Partial<ISftpConfigHandler>) {
        super(data);
        this.init(data);
    }

}