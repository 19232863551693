import _ from 'lodash';
import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/models/users.model';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';


@Component({
    selector: 'app-login-form',
    templateUrl: './login.form.html',
    styleUrls: ['./login.form.scss'],
})
export class LoginForm implements OnInit {
    @Output() isFormLoading: EventEmitter<boolean> = new EventEmitter();

    form: FormGroup;
    isLoading = false;
    returnUrl: string | null = null;

    get controls() { return this.form.controls; }
    get currentUser(): User | null { return this.authService.user; }
    resetPassword: string | null = null;
    prId: string | null = null;
    userId: string | null = null;

    @HostListener('keydown.enter', ['$event'])
    onEnterKey(event: KeyboardEvent) {
        this.onSubmit();
    }

    constructor(
        protected router: Router,
        protected formBuilder: FormBuilder,
        protected alertService: AlertService,
        protected authService: AuthService,
        protected route: ActivatedRoute,

    ) {
        this.form = this.formBuilder.group({
            username: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required)
        });
        this.userId = this.route.snapshot.queryParamMap.get('userId');
        this.resetPassword = this.route.snapshot.queryParamMap.get('resetPassword');
        this.prId = this.route.snapshot.queryParamMap.get('prId');
        this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
    }

    ngOnInit() {
    }

    onSubmit() {
        let username: string = this.controls.username.value.trim(),
            password: string = this.controls.password.value.trim();
        // $socketId: Id = this.io.socket.id;
        this.isLoading = true;
        this.isFormLoading.emit(true);

        const result = this.authService.login(username, password, /*{ $socketId }*/).then(result => {
            this.isLoading = false;
            this.isFormLoading.emit(false);
            const welcome_translated = this.authService.user?.lang === 'en' ? 'Welcome ' : 'Bienvenue ';
            this.alertService.success(welcome_translated + this.currentUser?.fullname);
            // using a force reload so the socket service can update his auth token
            setTimeout(() => {
                if (this.resetPassword && this.userId) {
                    window.location.href = `/#/user/reset-password/${this.userId}/1`;
                } else if (this.prId) {
                    window.location.href = `/#/splitview/${this.prId}`;
                } else if (this.returnUrl) {
                    window.location.href = `/#${decodeURI(this.returnUrl)}`;
                } else {
                    window.location.href = '/';
                }
            }, 1000);
        }).catch(e => {
            this.isLoading = false;
            this.isFormLoading.emit(false);
            this.alertService.error(_.isObject(e) ? (e as any).message || 'Unknown error' : e);
        });
    };

    isDisabled() {
        return this.form.invalid;
    }

    onForgotPassword() {
        this.router.navigate(['/reset-password']);
    }

}
