import { ProjectService } from 'src/app/services/project.service';
import { IArchiveSubRequest, IRelectureSubRequest, IRequest, Request } from '../request.model';
import _ from 'lodash';
import { MasterProjectService } from 'src/app/services/project-master.service';
import { RequestType } from 'src/app/LIVECO.define';


export enum shooting {
  FACE = "SHOOTINGSIDE.FACE",
  THREE_QUARTER_LEFT = "SHOOTINGSIDE.THREEQUARTERLEFT",
  THREE_QUARTER_RIGHT = "SHOOTINGSIDE.THREEQUARTERRIGHT",
  FACE_INCO = "SHOOTINGSIDE.FACEINCO",
}

export interface IPackshotRequest extends IRequest {
  shooting: shooting[];
  archive: IArchiveSubRequest;
}

export class PackshotRequest extends Request implements IPackshotRequest {

  override type: RequestType = RequestType.PACKSHOT;

  override relecture: IRelectureSubRequest = {
    members: [],
  }

  shooting: shooting[];

  get description(): string {
    return this.shooting.join(", ");
  }

  archive: IArchiveSubRequest = {
    source_sub_path: '3. HD',
  };

  override options: any = {
    ...this.options,
    rvf: {
      jobType: 'execution',
      dateIncrement: 2
    },
  };

  constructor(data: Partial<PackshotRequest>) {
    super(data);
    this.init(data);
  }

  onProjectCreation(service: ProjectService, master_service: MasterProjectService, lifecycle: 'init' | 'submit'): Promise<void> | void {
    switch (lifecycle) {
      case 'init':
        this.selectServiceInProject(service);
        this.insertCommCustomerInProject(service);
        this.insertRequestMemberInProjectMember(service);
        this.flagLimitedCreation(service);

        break;
      case 'submit':

        break;
    }
    super.onProjectCreation(service, master_service, lifecycle);
  }

  onProjectEdition(service: ProjectService): void {
    this.flagLimitedCreation(service);
    super.onProjectEdition(service);
  }

  private flagLimitedCreation(service: ProjectService) {
    service.s_requests.setFlag("project-limited-creation", ['base', 'riverflow']); 
  }

  private selectServiceInProject(service: ProjectService) {
    _.set(service.current || {}, 'custom.general.service_exe', false);
    _.set(service.current || {}, 'custom.general.service_hd', true);
    _.set(service.current || {}, 'custom.general.service_repro', false);
    _.set(service.current || {}, 'custom.general.service_other', false);

    // Remove objects from other services
    _.set(service.current || {}, 'custom.exe', undefined);
    _.set(service.current || {}, 'custom.repro', undefined);
    _.set(service.current || {}, 'custom.other', undefined);
}

  private insertCommCustomerInProject(service: ProjectService) {
    // Set request comm customer, name and reset service
    _.set(service.current || {}, 'custom.hd.commentaire_clienthd', this?.shooting.join(", "));
  }
  
  private insertRequestMemberInProjectMember(service: ProjectService) {
    // Set request member in project member
    _.set(service.current || {}, 'custom.members', this.relecture?.members);
    // flag the easydiadem service so member edit is disabled
    service.s_requests.setFlag("project-members-creation-locked", true);
}

}
