export enum TITLESPAGES {
  HOME_TITLE = 'Liveco 4.0 - Accueil',
  PROJECT_CREATE_TITLE = 'Liveco 4.0 - Création de projet',
  PROJECT_CREATE_MASTER_TITLE = 'Liveco 4.0 - Création projet parent',
  PROJECT_CREATE_CHILD_TITLE = 'Liveco 4.0 - Création projet enfant',
  PROJECT_EDIT_CHILD_TITLE = 'Liveco 4.0 - Edition projet enfant',
  PROJECT_BRIEF_TITLE = 'Liveco 4.0 - Brief projet enfant',
  PROJECTS_TITLE = 'Liveco 4.0 - Mes projets',
  STATS_TITLE = 'Liveco 4.0 - Statistique',
  KIOSK_TITLE = 'Liveco 4.0 - Cloudflow',
  FORM_CREATE_TITLE = 'Liveco 4.0 - Création formulaire',
  FORM_EDIT_TITLE = 'Liveco 4.0 - Edition formulaire',
  PRINTERS_TITLE = 'Liveco 4.0 - Imprimeurs',
  PRINTER_CREATE_TITLE = 'Liveco 4.0 - Création imprimeur',
  PRINTER_EDIT_TITLE = 'Liveco 4.0 - Edition imprimeur',
  COLORSBOOK_TITLE = 'Liveco 4.0 - Nuancier couleurs',
  FINISHBOOK_TITLE = 'Liveco 4.0 - Nuancier vernis',
  USERS_TITLE = 'Liveco 4.0 - Utilisateurs',
  PATCH_NOTES_TITLE = 'Liveco 4.0 - Evolutions',
  USER_CREATE_TITLE = 'Liveco 4.0 - Création utilisateur',
  USER_EDIT_TITLE = 'Liveco 4.0 - Edition utilisateur',
  GROUPS_TITLE = 'Liveco 4.0 - Groupes d\'utilisateurs',
  BRANDS_TITLE = 'Liveco 4.0 - Marques',
  GROUP_CREATE_TITLE = 'Liveco 4.0 - Création groupe d\'utilisateurs',
  GROUP_EDIT_TITLE = 'Liveco 4.0 - Edition groupe d\'utilisateurs',
  ACCOUNT_TITLE = 'Liveco 4.0 - Mon compte',
  NEW_REQUEST = 'Liveco 4.0 - Nouvelle requête',
  REQUESTS_TITLE = 'EASYDIADEM'
}


