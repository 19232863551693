import moment from "moment";
import { BaseModel } from "./base.model";

export interface ICustomer {
    _id: string;
    UUID: string;
    code: string;
    IDClient: number;
    IDSociete: number;
    name: string;
    group: string;
    creation_date: Date;
    flags?: {
        [key: string]: boolean;
    };
}

export class Customer extends BaseModel<ICustomer> implements ICustomer {

    _id: string;
    UUID: string;
    code: string;
    IDClient: number;
    IDSociete: number;
    name: string;
    group: string;
    creation_date: Date = moment().toDate();
    flags?: {
        [key: string]: boolean;
    };


    constructor(data: Partial<ICustomer>) {
        super(data);
        this.init(data);
    }

    public hasOption(optionName: string): boolean {
        if (this.flags && this.flags[optionName] !== undefined) {
            return this.flags[optionName];
        }
        return false;
    }
}
