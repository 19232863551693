import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import _ from 'lodash';
import { ResolverService } from './api/resolver.service';
import { KFeathers } from './api/feathers.service';
import { Group } from '../models/groups.model';
// import {BehaviorSubject} from 'rxjs/BehaviorSubject';

// @ts-ignore
// const api = require('@modules/cf-api.js').api;;
// const api_async = require('@modules/cf-api.js').api_async;

@Injectable({
    providedIn: 'root',
})
export class GroupService extends ResolverService<Group> {
    path: string = 'groups';
    model: new (any: any) => Group = Group;

    constructor(
        protected feathers: KFeathers,
    ) { super(feathers); }
}
