<!-- If mode 'ask-login', show form to ask for a password reset-->
<ng-container *ngIf="pageMode === 'ask-login'">
    <h3 class="flex"> {{ "FORGOT_PASSWORD" | translate}} </h3>
    <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{'USERNAME_OR_EMAIL' | translate}}</mat-label>
        <input matInput [(ngModel)]="inputLogin" required />
        <mat-error *ngIf="!inputLogin || inputLogin.length < 1"> {{'REQUIRED_USERNAME_OR_EMAIL'|translate}} </mat-error>
    </mat-form-field>
    <a class="text-tw-primary hover:text-tw-dark-primary cursor-pointer mb-3" (click)="backToLogin()">
        <div class="flex gap-2 items-center">
            <mat-icon>arrow_back</mat-icon>
            <span>{{'BACK_TO_LOGIN_PAGE' | translate}}</span>
        </div>
    </a>
    <div class="btn-container text-right">
        <button mat-flat-button [disabled]="isDisabled() || isLoading" color="primary" type="button" (click)="onSubmit()"> {{'ASK_FOR_REINIT'|translate}} </button>
    </div>
</ng-container>
<!-- If mode 'set-password', show form to set new password-->
<ng-container *ngIf="pageMode === 'set-password'">
    <h3 class="flex"> {{ "SET_NEW_PASSWORD" | translate}} </h3>
    <form [formGroup]="form" #formDirective="ngForm">
        <div>
            <mat-form-field  class="w-full">
                <mat-label>{{'USER.PASSWORD.NEW'| translate}}</mat-label>
                <input matInput formControlName="newPassword" class="w-full" placeholder="" [type]="showPassword ? 'text' : 'password'">
                <mat-icon matSuffix (click)="toggleVisibility()">{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                <span class="text input-error w-full" *ngIf="controls.newPassword.hasError('required') && controls.newPassword.pristine === false"> {{'REQUIRED'| translate}} </span>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field  class="w-full">
                <mat-label>{{'USER.PASSWORD.NEW_CONFIRM'| translate}}</mat-label>
                <input matInput formControlName="newPasswordConfirm" class="w-full" placeholder="" [type]="showPassword ? 'text' : 'password'">
                <span class="text input-error w-full" *ngIf="controls.newPasswordConfirm.hasError('required') && controls.newPasswordConfirm.pristine === false"> {{'REQUIRED'| translate}} </span>
                <span class="text input-error w-full" *ngIf="form.hasError('notSame') && controls.newPasswordConfirm.pristine === false"> {{'USER.PASSWORD.NOT_SAME' | translate}} </span>
            </mat-form-field>
        </div>
    </form>
    <a class="text-tw-primary hover:text-tw-dark-primary cursor-pointer mb-3" (click)="backToLogin()">
        <div class="flex gap-2 items-center">
            <mat-icon>arrow_back</mat-icon>
            <span>{{'BACK_TO_LOGIN_PAGE' | translate}}</span>
        </div>
    </a>
    <!-- <a class="text-blue-500 hover:text-blue-700 underline cursor-pointer mb-3" (click)="backToLogin()">{{'BACK_TO_LOGIN_PAGE'|translate}}</a> -->
    <div class="btn-container text-right">
        <button mat-flat-button [disabled]="!form.valid" color="primary" type="button" (click)="onSubmit()"> {{'USER.UPDATE'|translate}} </button>
    </div>
</ng-container>