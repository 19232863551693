import { Injectable } from "@angular/core";
import { Query, ResolverParams, ResolverService } from "./api/resolver.service";
import { KFeathers } from "./api/feathers.service";
import _ from "lodash";
import { Customer, ICustomer } from "../models/customer.model";
import { Contact } from "../models/customer-contact";


@Injectable({ providedIn: 'root' })
export class CustomerContactService extends ResolverService<Contact> {
    path: string = 'customers_contacts';
    model: new (any: any) => Contact = Contact;

    constructor(
        protected feather: KFeathers,
    ) {
        super(feather);
    }

}