import { Component, Inject } from '@angular/core';
import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import _ from 'lodash';
import { FlowType } from 'src/app/models/proofreading.model';

interface WarnProdFlowDialogOptions {
    title: string;
    text: string;
    relecture: number,
    valid: string;
    options: string[];
}


@Component({
    selector: 'app-warn-prod-flux',
    templateUrl: './warn-prod-flux.component.html',
    styleUrls: ['./warn-prod-flux.component.scss']
})
export class WarnProdFlowDialogComponent {
    protected _defaultCtx: WarnProdFlowDialogOptions = {
        title: '',
        text: '',
        relecture: 1,
        options: [],
        valid: 'YES',
    };

    completed: string[] = [];
    incompleted: string[] = [];

    public ctx: WarnProdFlowDialogOptions;

    constructor(
        public dialogRef: MatDialogRef<WarnProdFlowDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: WarnProdFlowDialogOptions
    ) {
        this.ctx = _.merge(this._defaultCtx, _.cloneDeep(this.data));
        this.incompleted.push(...this.ctx.options);
    }

    done(todo: string) {
        this.completed.push(todo);
        this.incompleted = this.incompleted.filter(s => s !== todo);
    }

    undone(todo: string) {
        this.incompleted.push(todo);
        this.completed = this.completed.filter(s => s !== todo);
    }

    allDone() {
        return !this.incompleted.length;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
