import _ from "lodash";


export type Id = string;

export abstract class BaseModel<T = any> {

    /** id of the entity in BDD */
    public _id: Id;

    public get isNew(): boolean {
        return _.isNil(this._id);
    }

    /** override this to set default values upon entity creation */
    // protected __defaults: Partial<T> = {};

    /** fields that need to be build using specified class ( ex: { role: Role } ) */
    protected __build: { [path: string]: new (data: any) => any; } = {};

    constructor(data: Partial<T> = {}) {
        // this.init(data);
    }

    /**
     * INIT the entity with data, clean reset the entity with default values
     * @param data
     * @param reset if set to true, reset the object by setting every enumerable field to undefined @default true
     * @returns
     */
    protected init(data: Partial<T> = {}) {
        // this.reset();
        // if (this.__defaults && !_.isEmpty(this.__defaults)) {
        //     this.assign(this.__defaults);
        // }
        this.patch(data);
        return this;
    }

    /**
     * Patch the entity with a partial data (not in BDD)
     * @param data
     * @param reset if set to true, reset the object by setting every enumerable field to undefined @default true
     * @returns
     */
    public patch(data: Partial<T> = {}) {
        if (data) {
            this.assign(data);
        }
        return this;
    }

    private assign(data: any) {
        _.extend(this, (data as any).toJSON ? (data as any).toJSON() : data);
        for (let member of Object.keys(this.__build)) {
            const current = _.get(data, member);
            if (current && _.isArray(current)) {
                _.set(this, member, current.map((c) => new this.__build[member](c)));
            } else if (current) {
                _.set(this, member, new this.__build[member](current));
            }
        }
    }

    /**
     * Check if a given path is populated (check if it's an object and != than ObjectId)
        TODO : Remane as popuplated is already used in models 
    * @param path
     */
    // public populated(path: string): boolean {
    //     return _.isObject(_.get(this, path));
    // }

    /**
     * Tell if there is a builder for the selected path
     * @param path 
     * @returns 
     */
    // public hasBuilder(path: string) {
    //     return !!this.__build[path];
    // }

    // /**
    //  * Build the selected submember by path
    //  * @param path 
    //  * @param data 
    //  * @returns 
    //  */
    // public build(path: string, data: any = null) {
    //     return new this.__build[path](data);
    // }

    public toJSON() {
        const data: any = _.cloneDeep(this);
        for (let key of Object.keys(this.__build)) {
            const current: any = _.get(this, key);
            if (current && _.isArray(current)) {
                _.set(data, key, current.map((c) => (c?.toJSON ? c?.toJSON() : c)));
            } else if (current) {
                _.set(data, key, current?.toJSON ? current?.toJSON() : current);
            }
        }
        return _.omit(data, ["__build", /*"__defaults", "created", "updated" */]);
    }

    // /**
    //  * set all enumerable values to undefined, usefull when updating from a partial data
    //  *
    //  */
    // private reset() {
    //     Object.keys(this)
    //         .filter((key) => !key.startsWith("$"))
    //         .forEach((key) => _.set(this, key, undefined));
    // }
}
