<div [ngClass]="[isLoading ? 'loading' : '']">
    <div class="h-screen max-w-[1440px] m-auto">
        <div class="flex justify-around items-center h-full">
            <div class="w-[40%]">
                <img id="cerf" src="/assets/img/cerf_diadem_resized.png" alt="Cerf stylisé" />
            </div>
            <div class="w-[40%]">
                <app-login-form (isFormLoading)="changeFormLoading($event)"></app-login-form>
            </div>
        </div>
    </div>
</div>