<div class="container container-user">
    <h1 class="title flex justify-center">{{'USER.MY_ACCOUNT' | translate}}</h1>
    <mat-toolbar class="form-toolbar">
        <mat-toolbar-row class="nav">
            <!-- <button mat-fab *ngIf="valid$ | async" color="accent" type="button" class="toolbarButton" id="submit" (click)="submitForm()" matTooltip="{{'USER.UPDATE' | translate}}">
                <span class="icon-save mat-icon"></span> -->
                <!-- <span class="txt-btn">{{'USER.UPDATE' | translate}}</span> -->
            <!-- </button> -->

            <button *ngIf="valid$ | async" mat-flat-button class="bg-tw-primary text-tw-text-light" (click)="submitForm()">
                <div class="flex gap-2 items-center">
                    <mat-icon>save</mat-icon>
                    <span>{{'USER.UPDATE' | translate}}</span>
                </div>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <app-my-account-info [(model)]="model" [(form)]="form" (valid)="this.valid$" (id)="this.id"></app-my-account-info>
</div>