import { Component, Inject } from '@angular/core';
import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import _ from 'lodash';

export interface ConfirmDialogOptions {
    title: string;
    text: string;
    valid: string;
    noConfirm: boolean;
    rawText?: boolean;
}


@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
    protected _defaultCtx: ConfirmDialogOptions = {
        title: '',
        text: '',
        valid: 'YES',
        noConfirm: false,
        rawText: false
    };

    public ctx: ConfirmDialogOptions;

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogOptions
    ) {
        this.ctx = _.merge(this._defaultCtx, _.cloneDeep(this.data));
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
