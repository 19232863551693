import _ from 'lodash';
import { MAT_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Component, Inject, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { Router } from '@angular/router';
//@ts-ignore
import Mustache from 'mustache';

@Component({
    selector: 'app-notif',
    templateUrl: './notif.component.html',
    styleUrls: ['./notif.component.scss']
})
export class NotifComponent implements OnInit {

    get classes(): any {
        return {
            success: this.options.success || false,
            error: this.options.error || false,
            'alert-text': true
        };
    }

    get options(): any {
        return this._options || {};
    }

    get date() {
        return this.options.date;
    }

    get message() {
        return Mustache.render(this.options.message, {
            ...this.options.data
        });
    }

    constructor(@Inject(MAT_SNACK_BAR_DATA) protected _options: any, protected alert: AlertService, protected router: Router) { }

    ngOnInit(): void { }

    isMessage(): boolean {
        return !this.isSuccess() && !this.isError();
    }

    isSuccess(): boolean {
        return this.options.success === true;
    }

    isError(): boolean {
        return this.options.error === true;
    }

    goToURL() {
        console.log(this);
        if (!this.options.opts?.external) {
            window.open('#/' + this.options.url, '_blank');
        } else {
            window.open(this.options.url, '_blank');
        }
    }

    dismiss(event: Event) {
        event.stopPropagation();
        this.alert.reset();
    }
}
