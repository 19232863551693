import _ from "lodash";
import { BaseModel } from "./base.model";
import { TVN } from "./tvn.model";


export interface ITranslatedText {
    type: 'legal' | 'marketing';
    label: string;
    position: { facing: boolean, back: boolean; };
    langs: { [lang: string]: string; };
}

export interface ITexts {
    langs: string[];
    texts: ITranslatedText[],
    tvn: TVN;
}

export interface ITextsVersion extends ITexts {
    date: Date;
}

export interface IProductionTexts {
    _id: string;
    // proofreadingID: string;
    project?: {
        identifier: string;
        ID: string;
    }
    history: {
        last: ITexts | null;
    };
    current: ITexts;
}


export class ProductionTexts extends BaseModel implements IProductionTexts {
    protected __build: { [path: string]: new (data: any) => any; } = {
        'current.tvn': TVN,
    };
    _id: string;
    // proofreadingID: string;
    project?: {
        identifier: string;
        ID: string;
    }
    current: ITexts = {
        langs: ['fr'],
        texts: [],
        tvn: new TVN()
    };
    history: {
        last: ITexts | null;
        versions: ITextsVersion[];
    } = {
            last: null,
            versions: [],
        };

    difference: boolean = false;

    get texts() {
        return {
            'legal': this.current.texts.filter(text => text.type === 'legal'),
            'marketing': this.current.texts.filter(text => text.type === 'marketing')
        };
    }

    get fields() {
        return this.current.texts.map(text => text.label);
    }

    get isDifferent():boolean {
        return this.difference;
    }

    constructor(data: Partial<IProductionTexts> = {}) {
        super(data);
        this.init(data);
        if (this.current.langs.length) {
            this.setLangs(this.current.langs);
        }
    }

    checkLastVersionChange(field: ITranslatedText, lang: string) {
        this.history.last = this.history.last || { langs: [], texts: [], tvn: new TVN() };
        const last = this.history.last.texts.find(text => text.label === field.label);
        if (!last) return false;
        return !_.isEqual(last.langs[lang], field.langs[lang]);
        // return !last || !_.isEqual(last.langs[lang], field.langs[lang]);
    }

    countLastVersionChange(lang?: string): number {
        if (!lang) return this.current.langs.reduce((acc, l) => acc + this.countLastVersionChange(l), 0) || 0;
        return this.current.texts.filter(text => this.checkLastVersionChange(text, lang)).length || 0;
    }

    countDifference(cachedText: IProductionTexts): number {
        const langDiff = _.isEqual(this.current.langs, cachedText.current.langs) ? 0 : 1;  
        
        const textDiff = _.sumBy(this.current.texts, (currentText) => {
            const ct: ITranslatedText | undefined = cachedText.current.texts.find(text => text.label === currentText.label);
            // text difference
            let diffs = !ct ? 1 : this.current.langs.reduce((acc, lang) => acc + (!ct.langs || currentText.langs[lang] !== ct.langs[lang] ? 1 : 0), 0);
            // position difference
            if (ct) {
                if (currentText.position.facing !== ct.position.facing) diffs++;
                if (currentText.position.back !== ct.position.back) diffs++;
            }
            return diffs;
        });

        // TVN 
        let tvnDiff = this.current.tvn.countDiff(cachedText.current.tvn);
        this.difference = (langDiff + textDiff + tvnDiff) > 0;
        // console.log('this.difference', this.difference);
        return textDiff + langDiff + tvnDiff;
    }

    setLangs(langs: string[]) {
        this.current.langs = langs;
        this.current.texts.forEach(text => text.langs = langs.reduce((adaptedobj, key) => ({ ...adaptedobj, [key]: _.get(text.langs, key, '') }), {}));
    }

    addField(label: string, type: 'legal' | 'marketing') {
        this.current.texts.push({
            type,
            label,
            position: { facing: false, back: false },
            langs: this.current.langs.reduce((adaptedobj, key) => ({ ...adaptedobj, [key]: '' }), {})
        });
    }

    removeField(label: string) {
        this.current.texts = this.current.texts.filter(text => text.label !== label);
    }
}