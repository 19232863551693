import { EventEmitter, Injectable } from "@angular/core";
import { ngxLoadingAnimationTypes, NgxLoadingConfig } from "@dchtools/ngx-loading-v18";
import { BehaviorSubject, Observable } from "rxjs";
import { IProgress } from "./api.service";

@Injectable({ providedIn: 'root' })
export class LoaderService {

    // GLOBAL LOADING
    protected _loading$: BehaviorSubject<boolean>;
    public get loading$(): Observable<boolean> { return this._loading$.asObservable(); };


    protected _loading: boolean;
    public get loading(): boolean { return this._loading; }
    public set loading(v: boolean) {
        // console.trace('loading', v);
        this._loading = v;
        this._loading$.next(this._loading);
    }

    // LOCAL LOADING
    protected _local$: BehaviorSubject<boolean>;
    public get local$(): Observable<boolean> { return this._loading$.asObservable(); };

    protected _local: boolean;
    public get local(): boolean { return this._local; }
    public set local(v: boolean) {
        this._local = v;
        this._local$.next(this._local);
    }


    public progress: EventEmitter<IProgress> = new EventEmitter<IProgress>();
    protected _config: NgxLoadingConfig;
    public get config(): NgxLoadingConfig { return this._config; }

    constructor() {
        this._loading$ = new BehaviorSubject<boolean>(false);
        this._loading = false;
        this._config = {
            animationType: ngxLoadingAnimationTypes.cubeGrid,
            backdropBorderRadius: '0',
            backdropBackgroundColour: 'rgba(250,250,250, 1)',
            fullScreenBackdrop: true,
            primaryColour: '#a5d256',
            secondaryColour: '#643615',
            tertiaryColour: '#FFFF00',
        };
    }
}
