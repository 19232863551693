import { ErrorHandler, NgModule } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { TranslateModule } from "@ngx-translate/core";
import { HttpParams } from "@angular/common/http";
import { CanDeactivateGuard } from "./shared/guard/can-deactivate/can-deactivate-guard.service";
import { DateFormatDirective } from "./shared/directives/date-format.directive";
import { PasswordFormComponent } from "./admin/users/components/password-form/password-form.component";
import { ResetPasswordComponent } from "./admin/users/pages/reset-password/reset-password.component";
import { MyAccountInfoComponent } from "./admin/users/components/my-account-info/my-account-info.component";
import { MyAccountComponent } from "./admin/users/pages/my-account/my-account.component";
import { LivecoAPIService } from "./services/liveco-api.service";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { ProofreadingService } from "./services/proofreading.service";
import { DashboardHistoryService } from "./services/dashboard-history.service";
import { KFeathers } from "./services/api/feathers.service";
import { AuthService } from "./services/auth.service";
import { LoginPage } from "./login/pages/login/login.page";
import { LoginForm } from "./login/components/home-choice/login/login.form";
import { AuthGuard } from "src/guards/auth.guard";
import { NotificationsService } from "./services/notifications.service";
import { WrapperComponent } from "./wrapper/wrapper.component";
import { FileService } from "./services/file.service";
import { StateMachineService } from "./services/machine-state.service";
import { ProjectService } from "./services/project.service";
import { CustomerService } from "./services/customer.service";
import { MembersService } from "./services/member.service";
import { ClickStopPropagation } from "src/directives/click-stop-propagation";
import { ProductionTextsService } from "./services/production-texts.service";
import { RequestsService } from "./services/requests.service";
import { GroupService } from "./services/groups.service";
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ResetPasswordPage } from "./login/pages/reset-password/reset-password.page";
import { CustomerContactService } from "./services/customer-contacts.service";
import { RiverflowJobTypeService } from "./services/l2pub-job-types.service";
import { BrandCategoriesService } from "./services/brand-categories.service";
import { ResetPasswordForm } from "./login/components/reset-password/reset-password.form";
import { init, browserTracingIntegration, createErrorHandler } from "@sentry/angular-ivy";
import { environment } from "src/environments/environment";
import packageJSON from "../../package.json";


export function localeFactory(authService: AuthService): string {
    return authService.user?.lang === "en" ? "en-US" : "fr-FR";
}

init({
    dsn: environment.glitchtip.dsn,
    environment: environment.glitchtip.environment,
    release: packageJSON.version,
    autoSessionTracking: false,
    tracesSampleRate: 0.01,
    integrations: [browserTracingIntegration()],
});

@NgModule({
    declarations: [
        AppComponent,
        LoginForm,
        LoginPage,
        ResetPasswordPage,
        ResetPasswordForm,
        MyAccountInfoComponent,
        MyAccountComponent,
        PasswordFormComponent,
        ResetPasswordComponent,
        ClickStopPropagation,
        DateFormatDirective,
        WrapperComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedModule,
    ],
    exports: [],
    providers: [
        KFeathers,
        AuthGuard,
        TranslateModule,
        HttpParams,
        // CanDeactivateGuard,
        Title,
        AuthService,
        LivecoAPIService,
        ProofreadingService,
        ProductionTextsService,
        DashboardHistoryService,
        RequestsService,
        NotificationsService,
        MembersService,
        FileService,
        StateMachineService,
        GroupService,
        ProjectService,
        CustomerService,
        CustomerContactService,
        RiverflowJobTypeService,
        BrandCategoriesService,
        { provide: MAT_DATE_LOCALE, useFactory: localeFactory, deps: [AuthService] },
        {
            provide: ErrorHandler, useValue: createErrorHandler({
                showDialog: false,
            }),
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
