import { AfterContentInit, Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';


@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnDestroy {

    code: string;
    message: string;

    protected subscription: Subscription = new Subscription();

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
    ) {
        this.subscription.add(this.route.params.subscribe(async (params) => {
            if (params && params['code']) {
                //Retrieve Project ID
                this.code = params['code'];
            }
        }));
        this.subscription.add(this.route.queryParams.subscribe(async (params) => {
            if (params && params['message']) {
                //Retrieve Project ID
                this.message = params['message'];
            }
        }));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
