import { RequestType } from "src/app/LIVECO.define";
import { FilePathDescriptor, IPlanSubRequest } from "../request.model";
import { CorrectionRequest } from "./correction-request.model";
import { ProjectService } from "src/app/services/project.service";
import { MasterProjectService } from "src/app/services/project-master.service";
import _ from "lodash";

export class PlanCorrectionRequest extends CorrectionRequest {
    override type: RequestType = RequestType.PLAN_CORRECTION;
    public static FILES: FilePathDescriptor = [
        { key: 'plan.file_plan' },
    ];

    plan: IPlanSubRequest = {
        file_plan: undefined,
    };

    override options: any = {
        ...this.options,
        rvf: {
            jobType: "execution",
            dateIncrement: 2
        }
    };

    constructor(data: Partial<PlanCorrectionRequest>) {
        super(data);
        this.init(data);
    }

   override onProjectCreation(service: ProjectService, master_service: MasterProjectService, lifecycle: "init" | "submit"): Promise<void> | void {
        switch (lifecycle) {
            case "init":
                this.clearPlanField(service);
                this.clearExeBrief(service);
                break;
            case "submit":
                break;
        }
        super.onProjectCreation(service, master_service, lifecycle);
   }
    
    private clearPlanField(service: ProjectService) {
      _.set(service.current || {}, 'custom.printing.plan_technique', '');
    }

    private clearExeBrief(service: ProjectService) {
        _.set(service.current || {}, 'custom.exe.text_brief', false);
        _.set(service.current || {}, 'custom.exe.commentaire_cpp', `Repartir de l'archive, et effectuer le changement de Plan.`);
    }
}
