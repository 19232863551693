import { Injectable, OnDestroy } from "@angular/core";
import { ResolverService } from "./api/resolver.service";
import { KFeathers } from "./api/feathers.service";
import { FlowType } from "../models/proofreading.model";
import _ from "lodash";
import { IPRApprovalHistoryRecord, IPRInternHistoryVersion, PRApprovalHistoryItem, PRExternHistoryItem, PRHistoryItem, PRHistoryItemFactory, PRInternHistoryItem } from "../models/proofreading-history.model";
import { BehaviorSubject, Subscription } from "rxjs";
import { ProjectService } from "./project.service";
import { LivecoAPIService } from "./liveco-api.service";
import { ChildProject } from "../models/projects/project.model";
import { Id } from "../models/base.model";

export interface IHistoriesHandler {
    flow: FlowType,
    id: Id,
    from_archive?: string,
    intern?: PRInternHistoryItem,
    extern?: PRExternHistoryItem,
    approval?: PRApprovalHistoryItem,
}

export interface IVersionSelector {
    id: Id,
    flow: FlowType;
    relecture: number;
    version?: number;
}

@Injectable({ providedIn: 'root' })
export class ProofreadingHistoryService extends ResolverService<PRHistoryItem> implements OnDestroy {
    path: string = 'proofreading-history';
    model: new (any: any) => PRHistoryItem = PRHistoryItem;


    protected subscription: Subscription = new Subscription();

    public compare = {
        intern: {
            version_A: new BehaviorSubject<IPRInternHistoryVersion | undefined>(undefined),
            version_B: new BehaviorSubject<IPRInternHistoryVersion | undefined>(undefined),
            url: new BehaviorSubject<string | null>(null)
        },
        approval: {
            version_A: new BehaviorSubject<IPRApprovalHistoryRecord | undefined>(undefined),
            version_B: new BehaviorSubject<IPRApprovalHistoryRecord | undefined>(undefined),
            url: new BehaviorSubject<string | null>(null)
        }
    };

    private _projectHistories: BehaviorSubject<IHistoriesHandler[]> = new BehaviorSubject<IHistoriesHandler[]>([]);
    get projectHistories$() {
        return this._projectHistories;
    }
    get projectHistories() {
        return this._projectHistories.value;
    }

    private _selectedVersion: BehaviorSubject<IVersionSelector | null> = new BehaviorSubject<IVersionSelector | null>(null);
    get selectedVersion$() {
        return this._selectedVersion;
    }
    get selectedVersion() {
        return this._selectedVersion.value;
    }

    // public relatedHistories: BehaviorSubject<IHistoriesHandler[]> = new BehaviorSubject<IHistoriesHandler[]>([]);

    constructor(protected feather: KFeathers,
        protected s_project: ProjectService,
        protected liveco: LivecoAPIService
    ) {
        super(feather);

        this.subscription.add(
            this.s_project.current$.subscribe(this.getRelatedProjectHistories.bind(this))
            // this.s_project.current$.subscribe(async (project) => {
            //     const newRelatedHistories = await this.getRelatedProjectHistories();
            //     this.relatedHistories.next(newRelatedHistories);
            // })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }



    public async getRelatedProjectHistories(project?: ChildProject, proofreadingsID?: Id[]) {
        if (!project && !proofreadingsID) {
            return;
        }

        const histories: IHistoriesHandler[] = [];
        // if the project have an archive number, get the history of the archive number
        if (project?.custom?.base?.id_archive) {
            console.log('[ARCHIVE HISTORY]', project.custom.base.id_archive);
            const result = await this.s_project.find({ 'identifier': project.custom.base.id_archive }, { $triggerEntityList: false });
            if (result.length) { histories.push(...(await this._getRelatedProjectHistories(result[0])).map(h => ({ ...h, from_archive: project.custom.base.id_archive }))); }
        }
        // get the history of the project
        histories.push(...await this._getRelatedProjectHistories(project, proofreadingsID));
        this._projectHistories.next(_.uniqBy(histories, 'id'));
        return histories;
    }

    private async _getRelatedProjectHistories(project?: ChildProject, proofreadingsID?: Id[]) {
        const histories: IHistoriesHandler[] = [];
        if (project) {
            await this.find({ 'project.ID': project._id });
        } else if (proofreadingsID) {
            await this.find({ proofreadingID: { $in: proofreadingsID } });
        }
        // get the history of current project and group them by proofreadingID
        const groupedHistories = _.groupBy(this.entities, 'proofreadingID');
        for (const id in groupedHistories) {
            const intern = _.find(groupedHistories[id], { type: 'intern' }) as PRInternHistoryItem | undefined;
            const extern = _.find(groupedHistories[id], { type: 'extern' }) as PRExternHistoryItem | undefined;
            const approval = _.find(groupedHistories[id], { type: 'approval' }) as PRApprovalHistoryItem | undefined;
            const flow: FlowType = extern?.flow || approval?.flow || intern?.flow || FlowType.EXE;
            histories.push({ id, flow, intern, extern, approval });
        }
        return histories.sort((a, b) => Object.keys(FlowType).indexOf(a.flow) - Object.keys(FlowType).indexOf(b.flow));;
    }
    // private async get

    public async getHistories(id: string, flow: FlowType): Promise<IHistoriesHandler> {
        return {
            id,
            flow,
            intern: await this.getHistory(id, 'intern', flow) as PRInternHistoryItem,
            extern: await this.getHistory(id, 'extern', flow) as PRExternHistoryItem,
            approval: await this.getHistory(id, 'approval', flow) as PRApprovalHistoryItem,
        };
    }

    public async getHistory(id: string, type: 'intern' | 'extern' | 'approval', flow: FlowType) {
        const result = (await this.find({ proofreadingID: id, type, flow }))[0];
        if (!result)
            return undefined;
        return PRHistoryItemFactory.make(result);

    }
}