import { ActivatedRoute, Router } from '@angular/router';
import { Component, HostListener, OnInit } from '@angular/core';
import { AlertService } from '../../../services/alert.service';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { LivecoAPIService } from 'src/app/services/liveco-api.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialog/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FormGroupComponent } from 'src/app/shared/pages/form-group/form-group.component';
import { LoaderService } from 'src/app/services/loader.service';
import { AuthService } from 'src/app/services/auth.service';


@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.page.html',
    styleUrls: ['./reset-password.page.scss']
})
export class ResetPasswordPage extends FormGroupComponent<any> implements OnInit {

    isLoading: boolean = false;
   
    constructor(
        protected dialog: MatDialog,
        protected route: ActivatedRoute,
        protected s_liveco: LivecoAPIService,
        protected translate: TranslateService,
        private fb: FormBuilder,
        protected router: Router,
        protected s_alert: AlertService,
        protected s_loader: LoaderService,
        protected s_auth: AuthService
    ) {
        super(route, router, s_alert, s_loader, s_auth);
    }

    public changeFormLoading = (e: boolean): void => {
        this.isLoading = e;
    };
}

