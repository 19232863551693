import _ from "lodash";
import { BaseModel } from "./base.model";
import { defaultTVN } from "../LIVECO.define";

export type TVNValues = (string | string[])[][];

export interface ITVN {
    labels: string[];
    values: TVNValues;
    override?: { [key: string]: { i: number, j: number, value: string; }[]; };
    computed(lang?: string): TVNValues;
}

export class TVN extends BaseModel<ITVN> implements ITVN {
    get labels(): string[] {
        return this.values.map(row => row[0] as string);
    };
    values: TVNValues;
    override?: { [key: string]: { i: number, j: number, value: string; }[]; };

    constructor(data: Partial<ITVN> = {}) {
        super(data);
        if (_.isEmpty(data)) {
            this.values = _.clone(defaultTVN);
        } else
            this.init(data);
    }

    computed(lang?: string): TVNValues {
        return this.values;
    }

    addRow() {
        const rowLength = this.values[0].length;
        this.values.push(Array(rowLength).fill(''));
    }

    removeRow(index: number) {
        this.values.splice(index, 1);
    }

    addColumn() {
        this.values.forEach(row => row.push(''));
    }

    removeColumn(index: number) {
        this.values.forEach(row => row.splice(index, 1));
    }

    countDiff(tvn: TVN): number {
        return _.differenceWith(this.values, tvn.values, _.isEqual).length;
    }
}